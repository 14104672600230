<template>
  <div >
      <SenderIDTable :parent="parent"/>
  </div>
</template>

<script>
import SenderIDTable from './senderidTable.vue'
import appConfig from '@/app.config'
export default {
    page: {
		title: 'Approved sender IDs',
		meta: [{ name: 'description', content: appConfig.description }],
	},
    name: 'approved-sender_ids',
    components: {
        SenderIDTable
    },
    data() {
        return {
            parent:{
                name:'approved',
                storeDataName: 'approvedSenderIDs',
                from: 'approved',
                status:'Approved'
            },
        }
    },
    methods: {

    },
    computed: {

    },
    mounted() {

    }
}
</script>

<style>

</style>