<template>
  <div class="sender-id-table-action-dialog">
    <header>
      <h5 style="color: #f7921c">Bulk Edit Sender IDs</h5>
    </header>
    <div class="top-section">
      <h5>Are you sure you want to perform this action?</h5>
      <p>
        You are about to perform this action on
        <span class="bold">{{ multiSelectedData.length }}</span> user(s).
      </p>
    </div>
    <div class="action-container">
      <el-button
        type="success"
        @click="approveBulk"
        :loading="approveLoading"
        v-if="
          !['approved', 'autoApproved'].some((item) =>
            $route.name.includes(item)
          )
        "
        >Approve</el-button
      >
      <el-button
        type="danger"
        @click="declineBulk"
        :loading="declineLoading"
        v-if="!['declined'].some((item) => $route.name.includes(item))"
        >Decline</el-button
      >
      <el-button
        type="warning"
        @click="holdBulk"
        :loading="holdLoading"
        v-if="
          !['pending user verification sender IDs'].some((item) =>
            $route.name.includes(item)
          )
        "
        >Hold</el-button
      >
    </div>
    <div class="cancel-selection">
      <el-button type="danger" @click="$emit('update:visible', false)"
        >Cancel</el-button
      >
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      multiSelectedData: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        approveLoading: false,
        declineLoading: false,
        holdLoading: false,
      };
    },
    computed: {
      selectedIDs() {
        return this.multiSelectedData.map((item) => item.id);
      },
    },
    methods: {
      async approveBulk() {
        this.approveLoading = true;
        const formData = new FormData();
        formData.append("ids[]", this.selectedIDs);
        formData.append("status", 1);
        this.$store
          .dispatch("senderid/bulkActionSenderID", formData)
          .then(() => {
            this.$message.success({
              message: "Sender IDs approved successfully",
              showClose: true,
            });
            this.$emit("update:visible", {
              success: true,
              ids: formData.getAll("ids[]"),
            });
          })
          .catch((error) => {
            this.$message.error({
              message: error.response.data.message,
              showClose: true,
            });
          })
          .finally(() => {
            this.approveLoading = false;
          });
      },
      async declineBulk() {
        this.declineLoading = true;
        const formData = new FormData();
        formData.append("ids[]", this.selectedIDs);
        formData.append("status", 2);
        this.$store
          .dispatch("senderid/bulkActionSenderID", formData)
          .then(() => {
            this.$message.success({
              message: "Sender IDs toggled successfully",
              showClose: true,
            });
            this.$emit("update:visible", {
              success: true,
              ids: formData.getAll("ids[]"),
            });
          })
          .catch((error) => {
            this.$message.error({
              message: error.response.data.message,
              showClose: true,
            });
          })
          .finally(() => {
            this.declineLoading = false;
          });
      },
      async holdBulk() {
        this.holdLoading = true;
        const formData = new FormData();
        formData.append("ids[]", this.selectedIDs);
        formData.append("status", 3);
        this.$store
          .dispatch("senderid/bulkActionSenderID", formData)
          .then(() => {
            this.$message.success({
              message: "Sender IDs toggled successfully",
              showClose: true,
            });
            this.$emit("update:visible", {
              success: true,
              ids: formData.getAll("ids[]"),
            });
          })
          .catch((error) => {
            this.$message.error({
              message: error.response.data.message,
              showClose: true,
            });
          })
          .finally(() => {
            this.holdLoading = false;
          });
      },
    },
  };
</script>

<style scoped>
  .sender-id-table-action-dialog {
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.481);
    width: 40rem;
    max-width: 100%;
    background-color: #fff;
    padding: 2rem;
    z-index: 2000;
    position: absolute;
    top: 56%;
    isolation: isolate;
    left: 45%;
  }
  .action-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel-selection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .bold {
    font-weight: bold;
    color: #f56c6c;
  }

  .top-section {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  header {
    margin-bottom: 2rem;
  }
</style>
